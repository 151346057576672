const removeExif = (file: File): any => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (typeof e?.target?.result === "string") {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            if (blob) {
              const updatedFile = new File([blob], file.name, {
                type: file.type,
              });
              resolve(updatedFile);
            } else {
              reject(new Error("Blob creation failed"));
            }
          }, file.type);
        };
        if (e?.target?.result) {
          img.src = e?.target?.result;
        } else {
          reject(new Error("No target result"));
        }
      } else {
        reject(new Error("FileReader result is not a string"));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const fileUploader = async (file: File, path: string, banner?: boolean) => {
  validateFile(file);
  const fileType = file.type.toLowerCase();
  let updatedFile: File = file;

  if (fileType.startsWith("image/") && !banner) {
    try {
      updatedFile = await removeExif(file);
    } catch (err) {
      console.log(err)
    }
  }
  
  const { url } = await uploadFile(updatedFile, path);
  return {
    message: "File uploaded successfully.",
    file: url.replace(
      `${process.env.NEXT_PUBLIC_DO_SPACES_ASSET_PATH_NAME}/uploads/`,
      ""
    ) as string,
  };
};

const validateFile = (file: File) => {
  if (!file) {
    throw new Error("Please upload a file to continue.");
  }
};

const uploadFile = async (file: File, path: string) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("path", path);

  let response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/upload`, {
    method: "POST",
    body: formData,
  });

  const jsonData = await response.json();

  // if (!response.ok) {
  //   throw new Error(jsonData.error);
  // }

  return jsonData;
};

export const getAssetPath = (filename: string | null) => {
  // Google Image
  if (filename?.includes("https://lh3.googleusercontent.com")) return filename;

  // LinkedIN Image
  if (filename?.includes("https://media.licdn.com")) return filename;

  return filename
    ? `${process.env.NEXT_PUBLIC_DO_SPACES_ASSET_URI}/uploads/${filename}`
    : `${process.env.NEXT_PUBLIC_DO_SPACES_ASSET_URI}/uploads/images/default-avatar.jpg`
};

export const  base64ToBlob = (base64: string, contentType: string) => {
  // Decode the base64 string to a binary string
  const byteCharacters = atob(base64);
  const byteArrays = [];

  // Split the binary string into 512-byte chunks
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }

  // Create a Blob from the byte arrays
  return new Blob(byteArrays, { type: contentType });
}
